<template>
  <fragment>
    <v-bottom-navigation
      class="nav-bar-bottom pa-4"
      height="75"
      light
      grow
      fixed>
      <!-- Projects -->
      <v-btn
        data-test="projects_sidebar_button"
        :to="{ name: 'projects', params: { wId: activeWorkspaceId } }"
        class="highlight-tab fs-11 darkGrey--text pa-0">
        <i class="nav-svg-icon icon-projects" />
        <span>Projects</span>
      </v-btn>

      <!-- Collections -->
      <v-btn
        data-test="collections_sidebar_button"
        :to="{ name: 'collections', params: { wId: activeWorkspaceId, id: getDefaultLibraryId } }"
        class="highlight-tab fs-11 darkGrey--text pa-0">
        <i class="nav-svg-icon icon-collections" />
        <span>Collections</span>
      </v-btn>

      <!-- Workspace toggle btn -->
      <div
        :class="[
          'workspace-toggle',
          'pointer relative top-n9',
          workspaceDrawer ? 'border-white toggle-shadow' : 'border-none',
        ]">
        <WorkspaceBubble
          :size="65"
          :class="[
            'fs-20 mainBlue white--text',
            workspaceDrawer ? '' : 'toggle-shadow',
          ]"
          @click.native.prevent="workspaceDrawer = !workspaceDrawer">
          <span>{{ getActiveWorkspaceName.substring(0, 2) }}</span>
        </WorkspaceBubble>
      </div>

      <!-- Community Collections -->
      <v-btn
        data-test="community_collections_sidebar_button"
        :to="{ name: 'community-collections', params: { wId: activeWorkspaceId, id: 'community' } }"
        class="highlight-tab fs-11 darkGrey--text pa-0">
        <i class="nav-svg-icon icon-community" />
        <span>Community</span>
      </v-btn>

      <!-- Account Btn -->
      <v-menu
        origin="bottom right"
        :open-on-click="true"
        :close-on-content-click="false"
        z-index="99">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            class="fs-11 darkGrey--text pa-0"
            v-on="on">
            <i class="nav-svg-icon icon-account" />
            <span>Account</span>
          </v-btn>
        </template>
        <AccountDropdown height="100%" />
      </v-menu>
    </v-bottom-navigation>

    <!-- Workspace Navigation -->
    <v-navigation-drawer
      v-model="workspaceDrawer"
      :overlay-opacity="0"
      class="workspace-drawer"
      bottom
      fixed
      floating
      hide-overlay
      temporary>
      <WorkspaceNav
        @close-drawer="workspaceDrawer = false" />
    </v-navigation-drawer>
  </fragment>
</template>
<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import AccountDropdown from '@/components/App/AppHeaderAccountMenu/AccountDropdown';
import WorkspaceBubble from '@/components/Workspace/WorkspaceBubble';
import WorkspaceNav from '@/components/Workspace/WorkspaceNav';
export default {
  name: 'AppNavBarBottom',
  components: {
    AccountDropdown,
    WorkspaceBubble,
    WorkspaceNav,
  },
  data: () => ({
    workspaceDrawer: false,
  }),
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('Workspace', ['getActiveWorkspaceName']),
    ...mapGetters('Libraries', ['getDefaultLibraryId']),
  },
};
</script>
<style scoped lang="scss">
.nav-bar-bottom.v-bottom-navigation {
  justify-content: space-evenly;
  box-shadow: 0px 0px 25px #35313526;
  z-index: 9;

  ::v-deep .v-btn {
    min-width: unset;

    &::before {
      display: none;
    }

    &__content {
      gap: 6px;
      color: inherit;
      flex-direction: column !important;
      line-height: inherit;
      letter-spacing: inherit;
    }

    &--active.highlight-tab {
      [class*="icon-"] {
        background-color: var(--v-mainGreen-base);
      }

      .v-btn__content {
        color: var(--v-mainGreen-base);;
      }
    }

    .nav-svg-icon {
      width: 25px;
    }
  }

  .workspace-toggle {
    height: min-content;
    border-radius: 100%;
  }
  .top-n9 {
    top: -36px;
  }

  .toggle-shadow {
    box-shadow: 0px 0px 25px rgba(53, 49, 53, 0.15);
  }

  .border-none {
    border: 3px solid transparent !important;
  }

  .border-white {
    border: 3px white solid !important;
  }

  // for account menu
  .v-menu__content {
    bottom: 75px;
    right: 0;
    left: unset !important;
    top: unset !important;
  }
}

// for workspace nav
.workspace-drawer {
  top: 0 !important;
  max-height: calc(100% - 75px);
  box-shadow: none;
}
</style>
