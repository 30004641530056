var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-bottom-navigation',{staticClass:"nav-bar-bottom pa-4",attrs:{"height":"75","light":"","grow":"","fixed":""}},[_c('v-btn',{staticClass:"highlight-tab fs-11 darkGrey--text pa-0",attrs:{"data-test":"projects_sidebar_button","to":{ name: 'projects', params: { wId: _vm.activeWorkspaceId } }}},[_c('i',{staticClass:"nav-svg-icon icon-projects"}),_c('span',[_vm._v("Projects")])]),_c('v-btn',{staticClass:"highlight-tab fs-11 darkGrey--text pa-0",attrs:{"data-test":"collections_sidebar_button","to":{ name: 'collections', params: { wId: _vm.activeWorkspaceId, id: _vm.getDefaultLibraryId } }}},[_c('i',{staticClass:"nav-svg-icon icon-collections"}),_c('span',[_vm._v("Collections")])]),_c('div',{class:[
        'workspace-toggle',
        'pointer relative top-n9',
        _vm.workspaceDrawer ? 'border-white toggle-shadow' : 'border-none' ]},[_c('WorkspaceBubble',{class:[
          'fs-20 mainBlue white--text',
          _vm.workspaceDrawer ? '' : 'toggle-shadow' ],attrs:{"size":65},nativeOn:{"click":function($event){$event.preventDefault();_vm.workspaceDrawer = !_vm.workspaceDrawer}}},[_c('span',[_vm._v(_vm._s(_vm.getActiveWorkspaceName.substring(0, 2)))])])],1),_c('v-btn',{staticClass:"highlight-tab fs-11 darkGrey--text pa-0",attrs:{"data-test":"community_collections_sidebar_button","to":{ name: 'community-collections', params: { wId: _vm.activeWorkspaceId, id: 'community' } }}},[_c('i',{staticClass:"nav-svg-icon icon-community"}),_c('span',[_vm._v("Community")])]),_c('v-menu',{attrs:{"origin":"bottom right","open-on-click":true,"close-on-content-click":false,"z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"fs-11 darkGrey--text pa-0"},'v-btn',attrs,false),on),[_c('i',{staticClass:"nav-svg-icon icon-account"}),_c('span',[_vm._v("Account")])])]}}])},[_c('AccountDropdown',{attrs:{"height":"100%"}})],1)],1),_c('v-navigation-drawer',{staticClass:"workspace-drawer",attrs:{"overlay-opacity":0,"bottom":"","fixed":"","floating":"","hide-overlay":"","temporary":""},model:{value:(_vm.workspaceDrawer),callback:function ($$v) {_vm.workspaceDrawer=$$v},expression:"workspaceDrawer"}},[_c('WorkspaceNav',{on:{"close-drawer":function($event){_vm.workspaceDrawer = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }